.cc {
    max-width: 1070px;
    width: 100%;
    margin: 0 auto;
    padding-right: 15px;
    padding-left: 15px;
}
.cont {
    max-width: 1470px;
    width: 100%;
    margin: 0 auto;
    padding-right: 15px;
    padding-left: 15px;
}
.heading {
  font-family: 'Avenir-M', sans-serif;
  color: $white;
  font-size: 36px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.035em;
}
@media(max-width: 600px) {
  .heading {
    font-size: 24px;
  }
}