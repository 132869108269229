@font-face {
    font-family: 'Avenir-L';
    src: url('/static/fonts/AvenirNextCyr-Light.eot');
    src: url('/static/fonts/AvenirNextCyr-Light.eot?#iefix') format('embedded-opentype'),
    url('/static/fonts/AvenirNextCyr-Light.woff') format('woff'),
    url('/static/fonts/AvenirNextCyr-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Avenir-M';
    src: url('/static/fonts/AvenirNextCyr-Medium.eot');
    src: url('/static/fonts/AvenirNextCyr-Medium.eot?#iefix') format('embedded-opentype'),
    url('/static/fonts/AvenirNextCyr-Medium.woff') format('woff'),
    url('/static/fonts/AvenirNextCyr-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Avenir-R';
    src: url('/static/fonts/AvenirNextCyr-Regular.eot');
    src: url('/static/fonts/AvenirNextCyr-Regular.eot?#iefix') format('embedded-opentype'),
    url('/static/fonts/AvenirNextCyr-Regular.woff') format('woff'),
    url('/static/fonts/AvenirNextCyr-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Avenir-B';
    src: url('/static/fonts/AvenirNextCyr-Bold.eot');
    src: url('/static/fonts/AvenirNextCyr-Bold.eot?#iefix') format('embedded-opentype'),
    url('/static/fonts/AvenirNextCyr-Bold.woff') format('woff'),
    url('/static/fonts/AvenirNextCyr-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}