body {
  font-family: 'Montserrat', sans-serif;
  background-color: $body;
  position: relative;
}
.container {
  max-width: 1450px;
  padding-left: 50px;
  padding-right: 50px;
}
@media only screen and (max-width: 600px) {
  header {
    z-index: 2;
    position: absolute;
    width: 100%;
    top: 50px;
    .main_nav {
      display: flex;
      justify-content: center;
      align-items: center;
      &__item {
        &:nth-child(0n+2) {
          display: none;
        }
        .logo {
          max-width: 270px;
          width: 100%;
          display: block;
          img {
            width: 100%;
          }
        } 
      }
      &__item-child {
        font-family: 'Avenir-B', sans-serif;
        text-align: right;
        line-height: 1.6;
        li {
          a {
            font-size: 36px;
            color: $white;
            text-decoration: none;
          }
          &:last-child{
            a {
              font-size: 31px;
            }
          }
        }
      }
    }
  }
  .main_wrapper {
    &__top-section {
      height: 100vh;
      position: relative;
      .overlay-background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgba(20, 20, 23, 0.2) 12.5%, rgba(21, 21, 28, 0.4) 40%, rgba(22, 23, 32, 0.6) 58.85%, rgba(23, 24, 37, 0.8) 78.12%, #181A2A 100%);
        z-index: 1;
      }
      .main_slider {
        height: 100%;
        .slick-dots {
          bottom: 0;
          right: 5%;
          width: initial;
          z-index: 2;
          .slick-active {
            button {
              &::before {
                content: '';
                background-image: url('../../static/img/active_slide.png');
              }
            }
          }
          li {
            button {
              &::before {
                content: '';
                background-image: url('../../static/img/slide_bullet.png');
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                opacity: 1;
              }
            }
          }
        }
        .item {
          background-size: contain;
          background-repeat: no-repeat;
          background-position: bottom right;
          height: 100vh;
        }
        .slide_1 {
          background: url('../../static/img/slider/slide_1.png');
        }
        .slide_2 {
          background: url('../../static/img/slider/slide_2.png');
        }
        .slide_3 {
          background: url('../../static/img/slider/slide_3.png');
        }
        .slide_4 {
          background: url('../../static/img/slider/slide_4.png');
        }
      }
      .bottom-slider {
        position: absolute;
        bottom: 15%;
        left: 0;
        right: 0;
        z-index: 1;
        .slide-text {
          max-width: 900px;
          font-family: 'Montserrat', sans-serif;
          font-size: 18px;
          line-height: 1.5;
          text-transform: uppercase;
          letter-spacing: 0.105em;
          color: $white;
          font-weight: 800;
        }
      }
      .slick-next, .slick-prev {
        width: 30px;
        height: 40px;
      }
      .slick-prev {
        left: initial;
        right: 18%;
        z-index: 1;
        margin-right: 35px;
        &::before {
          content: '';
          background-image: url('../../static/img/prev_arrow.svg');
          background-size: contain;
          background-repeat: no-repeat;
          display: block;
          width: 50px;
          height: 20px;
        }
      }
      .slick-next {
        right: 16%;
        &::before {
          content: '';
          background-image: url('../../static/img/next_arrow.svg');
          background-size: contain;
          background-repeat: no-repeat;
          display: block;
          width: 100px;
          height: 20px;
        }
      }
    }
    &__content-section {
      position: relative;
      .about-wr {
        margin-bottom: 50px;
        .container {
          .heading {
            position: relative;
            &::after {
              content: '';
              height: 3px;
              width: 50%;
              background-color: #fff;
              display: block;
              position: absolute;
              top: 50%;
              right: 0;
            }
          }
          .text-description {
            max-width: 950px;
            width: 100%;
            margin-top: 35px;
            color: $text;
            font-family: 'Avenir-L', sans-serif;
            font-size: 16px;
            line-height: 1.6;
            letter-spacing: 0.02em;
          }
        }
        .team-wr {
          position: relative;
          margin-top: 50px;
          .team_slider {
            max-width: 900px;
            width: 100%;
            margin: 0 0 0 auto;
            .slick-slide {
              margin: 0 auto;
              text-align: right;
            }
            .slick-next, .slick-prev {
              top: initial;
              bottom: -30px;
            }
            .slick-next {
              right: 30px;
              &::before {
                content: '';
                background-image: url('../../static/img/team/next.png');
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                width: 50px;
                height: 50px;
              }
            }
            .slick-prev {
              left: 0;
              &::before {
                content: '';
                background-image: url('../../static/img/team/prev.png');
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                width: 50px;
                height: 50px;
              }
            }
            .members {
              max-width: initial;
              width: 100%;
              margin-bottom: 50px;
              &__image {
                img {
                  width: 200px;
                  margin: 0 auto;
                }
              }
              &__name {
                margin-top: 15px;
                font-family: 'Avenir-R', sans-serif;
                color: $yellow;
                letter-spacing: 0.035em;
                font-size: 18px;
                text-align: left;
              }
              &__bottom {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                align-items: flex-start;
                margin-top: 20px;
                .position {
                  font-family: 'Avenir-L', sans-serif;
                  font-size: 14px;
                  color: $white;
                  letter-spacing: 0.035em;
                }
                .social_url {

                }
              }
            }
          }
        }
      }
      .services-wr {
        .heading {
          position: relative;
          line-height: 1.5;
          &::after {
            content: '';
            height: 3px;
            width: 50%;
            background-color: #fff;
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
          }
        }
        .subTitle {
          font-family: Avenir-M,sans-serif;
          color: #fff;
          font-size: 18px;
          font-weight: 800;
          text-transform: uppercase;
          letter-spacing: .035em;
          margin-top: 14px;
        }
        .serv-list {
          max-width: 800px;
          width: 100%;
          margin-top: 35px;
          &__item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 35px;
            .main-service {
              font-family: 'Avenir-M', sans-serif;
              font-weight: 600;
              font-size: 16px;
              line-height: 1.5;
              letter-spacing: 0.035em;
              color: $yellow;
              max-width: 350px;
              width: 100%;
              margin-bottom: 15px;
            }
            .service-description {
                max-width: 400px;
                width: 100%;
                color: $white;
                font-family: 'Avenir-L', sans-serif;
                font-size: 14px;
                line-height: 1.3;
                letter-spacing: 0.035em;
                color: $text;
            }
          }
        }
      }
    }
    &__projects {
      margin: 56px 0 76px;
      .heading {
        position: relative;
        &::after {
          content: '';
          height: 3px;
          width: 50%;
          background-color: #fff;
          display: block;
          position: absolute;
          top: 50%;
          right: 0;
        }
      }
      #map {
        height: 100%;
        width: 100%;
      }
      .project-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 40px;
        margin-bottom: 40px;
        &__side {
          width: 100%;
          padding-bottom: 35px;
          &:last-child {
            display: none;
          }
          &:nth-child(0n+1) {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          #map {
            padding-bottom: 35px;
          }
        }
        &__item {
          display: flex;
          flex-direction: column;
          margin-bottom: 35px;
          // padding-bottom: 35px;
        }
        &__info {
          margin-bottom: 25px;
          color: $text;
          font-family: 'Avenir-L', sans-serif;
          width: 100%;
          .title {
            font-family: 'Avenir-L', sans-serif;
            font-size: 20px;
            letter-spacing: 0.035em;
            color: $yellow;
          }
          .project_info {
            margin: 20px auto;
            font-family: 'Avenir-L', sans-serif;
            color: $text;
            font-size: 16px;
            line-height: 1.4;
          }
          .info_bottom {
            display: flex;
            align-items: center;
            .project_location {
              flex: 0 0 70%;
              cursor: pointer;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 0.035em;
              text-decoration: underline;
              padding-left: 35px;
              position: relative;
              &::before {
                content: '';
                background-image: url('../../static/img/location.svg');
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                width: 20px;
                height: 25px;
                position: absolute;
                left: 0;
              }
            }
            .doc_link {
              flex: 0 0 30%;
              text-align: right;
            }
          }
        }
        &__image {
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .show_more_btn {
        max-width: 222px;
        width: 100%;
        height: 50px;
        margin: 0 auto;
        .show_more {
          background: none;
          color: $text;
          border: 1px solid $white;
          font-family: 'Avenir-L', sans-serif;
          font-weight: 500;
          font-size: 20px;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          // padding: 15px 25px;
          text-decoration: none;
          justify-content: center;
          letter-spacing: 0.035em;
          
        }
      }
    }
    &__partners {
      .heading {
        position: relative;
        &::after {
          content: '';
          height: 3px;
          width: 50%;
          background-color: #fff;
          display: block;
          position: absolute;
          top: 50%;
          right: 0;
        }
      }
      .partner_list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 80px 0;
        .partner_item {
          flex: 0 0 100%;
          margin-bottom: 35px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .site-footer {
    .heading {
      position: relative;
      &::after {
        content: '';
        height: 3px;
        width: 50%;
        background-color: #fff;
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
      }
    }
    .contact-wr {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin: 80px 0;
      &__item {
        flex: 0 0 100%;
        
        &:first-child {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
        }
        .footer_logo {
          max-width: 240px;
          width: 100%;
          flex: 0 0 100%;
          order: 1;
          img {
            width: 100%;
          }
        }
        .info-list {
          flex: 0 0 100%;
          order: 2;
          margin: 25px 0;
          ul {
            padding-left: 20px;
            li {
              font-family: 'Avenir-L', sans-serif;
              font-size: 12px;

              &:first-child {
                line-height: 30px;  
              }
              a {
                letter-spacing: 0.035em;
                color: $yellow;
                text-decoration: none;
              }
            }
          }
        }
        .contact-location {
          text-align: right;
          color: $yellow;
          font-family: 'Avenir-L', sans-serif;
          font-size: 12px;
          letter-spacing: 0.035em;
          position: relative;
          line-height: 1.5;
          padding-left: 20px;
          &::before {
            content: '';
            background-image: url('../../static/img/location.svg');
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            width: 15px;
            height: 15px;
            position: absolute;
            left: 0;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 600px) {
  header {
    z-index: 2;
    position: absolute;
    width: 100%;
    top: 50px;
    .main_nav {
      display: flex;
      justify-content: center;
      align-items: center;
      &__item {
        &:nth-child(0n+2) {
          display: none;
        }
        .logo {
          max-width: 270px;
          width: 100%;
          display: block;
          img {
            width: 100%;
          }
        } 
      }
      &__item-child {
        font-family: 'Avenir-B', sans-serif;
        text-align: right;
        line-height: 1.6;
        li {
          a {
            font-size: 36px;
            color: $white;
            text-decoration: none;
          }
          &:last-child{
            a {
              font-size: 31px;
            }
          }
        }
      }
    }
  }
  .main_wrapper {
    &__top-section {
      height: 100vh;
      position: relative;
      .overlay-background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgba(20, 20, 23, 0.2) 12.5%, rgba(21, 21, 28, 0.4) 40%, rgba(22, 23, 32, 0.6) 58.85%, rgba(23, 24, 37, 0.8) 78.12%, #181A2A 100%);
        z-index: 1;
      }
      .main_slider {
        height: 100%;
        .slick-dots {
          bottom: 0;
          right: 5%;
          width: initial;
          z-index: 2;
          .slick-active {
            button {
              &::before {
                content: '';
                background-image: url('../../static/img/active_slide.png');
              }
            }
          }
          li {
            button {
              &::before {
                content: '';
                background-image: url('../../static/img/slide_bullet.png');
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                opacity: 1;
              }
            }
          }
        }
        .item {
          background-size: contain;
          background-repeat: no-repeat;
          background-position: bottom right;
          height: 100vh;
        }
        .slide_1 {
          background: url('../../static/img/slider/slide_1.png');
        }
        .slide_2 {
          background: url('../../static/img/slider/slide_2.png');
        }
        .slide_3 {
          background: url('../../static/img/slider/slide_3.png');
        }
        .slide_4 {
          background: url('../../static/img/slider/slide_4.png');
        }
      }
      .bottom-slider {
        position: absolute;
        bottom: 15%;
        left: 0;
        right: 0;
        z-index: 1;
        .slide-text {
          max-width: 900px;
          font-family: 'Montserrat', sans-serif;
          font-size: 18px;
          line-height: 1.5;
          text-transform: uppercase;
          letter-spacing: 0.105em;
          color: $white;
          font-weight: 800;
        }
      }
      .slick-next, .slick-prev {
        width: 30px;
        height: 40px;
      }
      .slick-prev {
        left: initial;
        right: 18%;
        z-index: 1;
        margin-right: 35px;
        &::before {
          content: '';
          background-image: url('../../static/img/prev_arrow.svg');
          background-size: contain;
          background-repeat: no-repeat;
          display: block;
          width: 50px;
          height: 20px;
        }
      }
      .slick-next {
        right: 16%;
        &::before {
          content: '';
          background-image: url('../../static/img/next_arrow.svg');
          background-size: contain;
          background-repeat: no-repeat;
          display: block;
          width: 100px;
          height: 20px;
        }
      }
    }
    &__content-section {
      position: relative;
      .about-wr {
        margin-bottom: 50px;
        .container {
          .heading {
            position: relative;
            &::after {
              content: '';
              height: 3px;
              width: 50%;
              background-color: #fff;
              display: block;
              position: absolute;
              top: 50%;
              right: 0;
            }
          }
          .text-description {
            max-width: 950px;
            width: 100%;
            margin-top: 35px;
            color: $text;
            font-family: 'Avenir-L', sans-serif;
            font-size: 16px;
            line-height: 1.6;
            letter-spacing: 0.02em;
          }
        }
        .team-wr {
          position: relative;
          margin-top: 50px;
          .team_slider {
            max-width: 900px;
            width: 100%;
            margin: 0 0 0 auto;
            .slick-slide {
              margin: 0 auto;
              text-align: right;
            }
            .slick-next, .slick-prev {
              top: initial;
              bottom: -30px;
            }
            .slick-next {
              right: 30px;
              &::before {
                content: '';
                background-image: url('../../static/img/team/next.png');
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                width: 50px;
                height: 50px;
              }
            }
            .slick-prev {
              left: 0;
              &::before {
                content: '';
                background-image: url('../../static/img/team/prev.png');
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                width: 50px;
                height: 50px;
              }
            }
            .members {
              max-width: initial;
              width: 100%;
              margin-bottom: 50px;
              &__image {
                img {
                  width: 200px;
                  margin: 0 auto;
                }
              }
              &__name {
                margin-top: 15px;
                font-family: 'Avenir-R', sans-serif;
                color: $yellow;
                letter-spacing: 0.035em;
                font-size: 18px;
                text-align: left;
              }
              &__bottom {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                align-items: flex-start;
                margin-top: 20px;
                .position {
                  font-family: 'Avenir-L', sans-serif;
                  font-size: 14px;
                  color: $white;
                  letter-spacing: 0.035em;
                }
                .social_url {

                }
              }
            }
          }
        }
      }
      .services-wr {
        .heading {
          position: relative;
          line-height: 1.5;
          font-size: 20px;
          &::after {
            content: '';
            height: 3px;
            width: 50%;
            background-color: #fff;
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
          }
        }
        .subTitle {
          font-family: Avenir-M,sans-serif;
          color: #fff;
          font-size: 18px;
          font-weight: 800;
          text-transform: uppercase;
          letter-spacing: .035em;
          margin-top: 14px;
        }
        .serv-list {
          max-width: 1000px;
          width: 100%;
          margin-top: 35px;
          &__item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 35px;
            background: rgba(32, 34, 48, 0.3);
            padding: 15px;
            .main-service {
              font-family: 'Avenir-M', sans-serif;
              font-weight: 600;
              font-size: 16px;
              line-height: 1.5;
              letter-spacing: 0.035em;
              color: $yellow;
              max-width: 350px;
              width: 100%;
              margin-bottom: 15px;
            }
            .service-description {
                max-width: 400px;
                width: 100%;
                color: $white;
                font-family: 'Avenir-L', sans-serif;
                font-size: 14px;
                line-height: 1.3;
                letter-spacing: 0.035em;
                color: $text;
            }
          }
        }
      }
    }
    &__projects {
      margin: 56px 0 76px;
      .heading {
        position: relative;
        &::after {
          content: '';
          height: 3px;
          width: 50%;
          background-color: #fff;
          display: block;
          position: absolute;
          top: 50%;
          right: 0;
        }
      }
      #map {
        height: 100%;
        width: 100%;
      }
      .project-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 40px;
        margin-bottom: 40px;
        &__side {
          width: 100%;
          padding-bottom: 35px;
          &:last-child {
            display: none;
          }
          &:nth-child(0n+1) {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          #map {
            padding-bottom: 35px;
          }
        }
        &__item {
          display: flex;
          flex-direction: column;
          margin-bottom: 35px;
          // padding-bottom: 35px;
        }
        &__info {
          margin-bottom: 25px;
          color: $text;
          font-family: 'Avenir-L', sans-serif;
          width: 100%;
          .title {
            font-family: 'Avenir-L', sans-serif;
            font-size: 20px;
            letter-spacing: 0.035em;
            color: $yellow;
          }
          .project_info {
            margin: 20px auto;
            font-family: 'Avenir-L', sans-serif;
            color: $text;
            font-size: 16px;
            line-height: 1.4;
          }
          .info_bottom {
            display: flex;
            align-items: center;
            .project_location {
              flex: 0 0 70%;
              cursor: pointer;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 0.035em;
              text-decoration: underline;
              padding-left: 35px;
              position: relative;
              &::before {
                content: '';
                background-image: url('../../static/img/location.svg');
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                width: 20px;
                height: 25px;
                position: absolute;
                left: 0;
              }
            }
            .doc_link {
              flex: 0 0 30%;
              text-align: right;
            }
          }
        }
        &__image {
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .show_more_btn {
        max-width: 222px;
        width: 100%;
        height: 50px;
        margin: 0 auto;
        .show_more {
          background: none;
          color: $text;
          border: 1px solid $white;
          font-family: 'Avenir-L', sans-serif;
          font-weight: 500;
          font-size: 20px;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          // padding: 15px 25px;
          text-decoration: none;
          justify-content: center;
          letter-spacing: 0.035em;
          
        }
      }
    }
    &__partners {
      .heading {
        position: relative;
        &::after {
          content: '';
          height: 3px;
          width: 50%;
          background-color: #fff;
          display: block;
          position: absolute;
          top: 50%;
          right: 0;
        }
      }
      .partner_list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 80px 0;
        .partner_item {
          flex: 0 0 100%;
          margin-bottom: 35px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .site-footer {
    .heading {
      position: relative;
      &::after {
        content: '';
        height: 3px;
        width: 50%;
        background-color: #fff;
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
      }
    }
    .contact-wr {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin: 80px 0;
      &__item {
        flex: 0 0 100%;
        
        &:first-child {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
        }
        .footer_logo {
          max-width: 240px;
          width: 100%;
          flex: 0 0 100%;
          order: 1;
          img {
            width: 100%;
          }
        }
        .info-list {
          flex: 0 0 100%;
          order: 2;
          margin: 25px 0;
          ul {
            padding-left: 20px;
            li {
              font-family: 'Avenir-L', sans-serif;
              font-size: 12px;

              &:first-child {
                line-height: 30px;  
              }
              a {
                letter-spacing: 0.035em;
                color: $yellow;
                text-decoration: none;
              }
            }
          }
        }
        .contact-location {
          text-align: right;
          color: $yellow;
          font-family: 'Avenir-L', sans-serif;
          font-size: 12px;
          letter-spacing: 0.035em;
          position: relative;
          line-height: 1.5;
          padding-left: 20px;
          &::before {
            content: '';
            background-image: url('../../static/img/location.svg');
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            width: 15px;
            height: 15px;
            position: absolute;
            left: 0;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) {
  .main_wrapper {
    &__top-section {
      // .bottom-slider {
      //   bottom: 50%;
      //   .item {
      //     font-size: 24px;
      //     line-height: 2;
      //   }
      // }
      .slick-prev, .slick-next {
        top: initial;
        bottom: 5%;
        z-index: 3;
      }
      .slick-next {
        right: 10%;
      }
      .slick-prev {
        right: 15%;
      }
      .bottom-slider {
        .slide-text {
          font-size: 24px;
        }
      }
    }
  }
}
@media only screen and (min-width: 992px) {
  
  header {
    z-index: 2;
    position: absolute;
    width: 100%;
    top: 50px;
    .main_nav {
      display: flex;
      justify-content: space-between;
      &__item {
        .logo {
          max-width: 270px;
          width: 100%;
          display: block;
          img {
            width: 100%;
          }
        }
        &:nth-child(0n+2) {
          display: block;
        }
      }
      &__item-child {
        font-family: 'Avenir-B', sans-serif;
        text-align: right;
        line-height: 1.6;
        li {
          a {
            font-size: 36px;
            color: $white;
            text-decoration: none;
          }
          &:last-child{
            a {
              font-size: 31px;
            }
          }
        }
      }
    }
  }
  .main_wrapper {
    &__top-section {
      // background: url('../../static/img/top_bg.png');
      // background-repeat: no-repeat;
      // background-size: cover;
      // background-position: bottom right;
      height: 100vh;
      position: relative;
      .bottom-slider {
        position: absolute;
        bottom: 15%;
        left: 0;
        right: 0;
        .slick-slide {
          
        }
        .slider-container  {
          max-width: 1060px;
          width: 100%;
        }
        .slick-dots {
          bottom: 20px;
          right: 0;
          width: initial;
          .slick-active {
            button {
              &::before {
                content: '';
                background-image: url('../../static/img/active_slide.png');
              }
            }
          }
          li {
            button {
              &::before {
                content: '';
                background-image: url('../../static/img/slide_bullet.png');
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                opacity: 1;
              }
            }
          }
        }
        .item {
          max-width: 700px;
          font-family: 'Montserrat', sans-serif;
          font-size: 26px;
          line-height: 1.5;
          text-transform: uppercase;
          letter-spacing: 0.105em;
          color: $white;
          font-weight: 800;
        }
        .slick-next, .slick-prev {
          width: 30px;
          height: 40px;
          display: block !important;
        }
        .slick-prev {
          left: initial;
          right: 0;
          z-index: 1;
          margin-right: 35px;
          &::before {
            content: '';
            background-image: url('../../static/img/prev_arrow.svg');
            background-size: contain;
            background-repeat: no-repeat;
            display: block;
            width: 50px;
            height: 20px;
          }
        }
        .slick-next {
          &::before {
            content: '';
            background-image: url('../../static/img/next_arrow.svg');
            background-size: contain;
            background-repeat: no-repeat;
            display: block;
            width: 100px;
            height: 20px;
          }
        }
      }
    }
    &__content-section {
      position: relative;
      &::before {
        content: '';
        background-image: url('../../static/img/section_bg.png');
        background-size: cover;
        background-position: left;
        display: block;
        position: absolute;
        width: 80%;
        height: 36%;
        z-index: -1;
      }
      &::after {
        content: '';
        background-image: url('../../static/img/section_bg_1.png');
        background-size: cover;
        background-position: right;
        position: absolute;
        display: block;
        width: 80%;
        height: 30%;
        z-index: -1;
        right: 0;
        bottom: 0;
      }
      .about-wr {
        margin-bottom: 50px;
        .container {
          .heading {
            position: relative;
            &::after {
              content: '';
              height: 3px;
              width: 65%;
              background-color: #fff;
              display: block;
              position: absolute;
              top: 50%;
              right: 0;
            }
          }
          .text-description {
            max-width: 950px;
            width: 100%;
            margin-top: 35px;
            color: $text;
            font-family: 'Avenir-L', sans-serif;
            font-size: 18px;
            line-height: 1.4;
            letter-spacing: 0.02em;
          }
        }
        .team-wr {
          position: relative;
          margin-top: 50px;
          .team_slider {
            max-width: 900px;
            width: 100%;
            margin: 0 0 0 auto;
            .slick-slide {
              margin: 0 auto;
              text-align: right;
            }
            .slick-next, .slick-prev {
              top: initial;
              bottom: -30px;
              width: 60px;
            }
            .slick-next {
              right: 30px;
              &::before {
                content: '';
                background-image: url('../../static/img/team/next.png');
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                width: 50px;
                height: 50px;
              }
            }
            .slick-prev {
              left: 70px;
              &::before {
                content: '';
                background-image: url('../../static/img/team/prev.png');
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                width: 50px;
                height: 50px;
              }
            }
            .members {
              max-width: 228px;
              width: 100%;
              margin-bottom: 50px;
              &__image {
                img {
                  width: 100%;
                }
              }
              &__name {
                margin-top: 15px;
                font-family: 'Avenir-R', sans-serif;
                color: $yellow;
                letter-spacing: 0.035em;
                font-size: 18px;
                text-align: left;
              }
              &__bottom {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                align-items: flex-start;
                margin-top: 20px;
                .position {
                  font-family: 'Avenir-L', sans-serif;
                  font-size: 18px;
                  color: $white;
                  letter-spacing: 0.035em;
                }
                .social_url {

                }
              }
            }
          }
        }
      }
      .services-wr {
        .heading {
          position: relative;
          &::after {
            content: '';
            height: 3px;
            width: 65%;
            background-color: #fff;
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
          }
        }
        .serv-list {
          max-width: 1000px;
          width: 100%;
          margin-top: 35px;
          &__item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 35px;
            background: rgba(32, 34, 48, 0.3);
            padding: 15px;
            .main-service {
              font-family: 'Avenir-M', sans-serif;
              font-weight: 600;
              font-size: 17px;
              line-height: 1.5;
              letter-spacing: 0.035em;
              color: $yellow;
              max-width: 350px;
              width: 100%;
            }
            .service-description {
                max-width: 400px;
                width: 100%;
                color: $white;
                font-family: 'Avenir-L', sans-serif;
                font-size: 16px;
                line-height: 1.3;
                letter-spacing: 0.035em;
                color: $text;
            }
          }
        }
      }
    }
    &__projects {
      margin: 56px 0 76px;
      .heading {
        position: relative;
        &::after {
          content: '';
          height: 3px;
          width: 65%;
          background-color: #fff;
          display: block;
          position: absolute;
          top: 50%;
          right: 0;
        }
      }
      #map {
        height: 100%;
        width: 100%;
      }
      .project-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 40px;
        margin-bottom: 40px;
        &__side {
          width: 49%;
          padding-bottom: 35px;
          &:nth-child(0n+1) {
            width: 49%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          &:last-child {
            display: block;
          }
          #map {
            padding-bottom: 35px;
          }
        }
        &__item {
          display: flex;
          // margin-bottom: 35px;
          // padding-bottom: 35px;
          &:nth-child(0n+2) {
            margin-top: 3%;
            margin-bottom: 3%;        
          }
        }
        &__info {
          padding-right: 20px;
          color: $text;
          font-family: 'Avenir-L', sans-serif;
          .title {
            font-family: 'Avenir-L', sans-serif;
            font-size: 20px;
            letter-spacing: 0.035em;
            color: $yellow;
          }
          .project_info {
            margin: 20px auto;
            font-family: 'Avenir-L', sans-serif;
            color: $text;
            font-size: 14px;
            line-height: 1.4;
          }
          .info_bottom {
            display: flex;
            align-items: center;
            .project_location {
              flex: 0 0 70%;
              cursor: pointer;
              font-size: 10px;
              line-height: 16px;
              letter-spacing: 0.035em;
              text-decoration: underline;
              padding-left: 35px;
              position: relative;
              &::before {
                content: '';
                background-image: url('../../static/img/location.svg');
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                width: 20px;
                height: 25px;
                position: absolute;
                left: 0;
              }
            }
            .doc_link {
              flex: 0 0 30%;
              text-align: right;
            }
          }
        }
        &__image {
          img {
            // height: 100%;
          }
        }
      }
      .show_more_btn {
        max-width: 222px;
        width: 100%;
        height: 50px;
        .show_more {
          color: $text;
          border: 1px solid $white;
          font-family: 'Avenir-L', sans-serif;
          font-weight: 500;
          font-size: 20px;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          // padding: 15px 25px;
          text-decoration: none;
          justify-content: center;
          letter-spacing: 0.035em;
          
        }
      }
    }
    &__partners {
      .heading {
        position: relative;
        &::after {
          content: '';
          height: 3px;
          width: 65%;
          background-color: #fff;
          display: block;
          position: absolute;
          top: 50%;
          right: 0;
        }
      }
      .partner_list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 80px 0;
        .partner_item {
          flex: 0 0 30%;
          &:first-child {
            text-align: left;
          }
          &:nth-child(0n+2) {
            text-align: center;
          }
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }
  .site-footer {
    .heading {
      position: relative;
      &::after {
        content: '';
        height: 3px;
        width: 65%;
        background-color: #fff;
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
      }
    }
    .contact-wr {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 80px 0;
      &__item {
        flex: 0 0 60%;
        &:first-child {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
        }
        &:last-child {
          flex: 0 0 auto;
        }
        .footer_logo {
          max-width: 240px;
          width: 100%;
          flex: 0 0 35%;
          img {
            width: 100%;
          }
        }
        .info-list {
          flex: 0 0 60%;
          ul {
            li {
              font-family: 'Avenir-L', sans-serif;
              font-size: 17px;
              &:first-child {
                line-height: 30px;  
              }
              a {
                letter-spacing: 0.035em;
                color: $yellow;
                text-decoration: none;
              }
            }
          }
        }
        .contact-location {
          text-align: right;
          color: $yellow;
          font-family: 'Avenir-L', sans-serif;
          font-size: 17px;
          letter-spacing: 0.035em;
          position: relative;
          padding-left: 55px;
          line-height: 1.5;
          &::before {
            content: '';
            background-image: url('../../static/img/location.svg');
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            width: 20px;
            height: 27px;
            position: absolute;
            // bottom: 0;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1199px) {
  .main_wrapper {
    &__top-section {
      .slick-next, .slick-prev {
        display: initial;
        top: initial;
        bottom: 5%;
        z-index: 2;
      }
      .slick-next {
        right: 5%;
        width: 50px;
      }
      .slick-prev {
        right: 8%;
        width: 50px;
      }
      .bottom-slider {
        .slide-text {
          font-size: 32px;
        }
      }
    }
    &__projects {
      .project-list {
        &__item {
          flex-direction: row;
        }
        &__info {
          width: 50%;
        }
        &__side {
          #map {
            .show_map {
              color: #17192b;
            }
          }
        }
      }
    }
  }
}